<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
      style="margin-bottom: 0px"
    >
      <div class="m-2">
        <b-row>
          <b-col
            md="8"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <p class="h5 mt-50 mr-75 ml-50">
              조회 기간
            </p>
            <b-form-radio-group
              v-if="false"
              v-model="query.searchType"
            >
              <b-form-radio :value="1">
                의원
              </b-form-radio>
              <b-form-radio :value="2">
                의사
              </b-form-radio>
            </b-form-radio-group>
            <b-form-datepicker
              v-model="query.searchStartDate"
              class="el-def"
              placeholder="게시시작일(Fr)"
              style="width: 250px"
            />
            <label class="ml-25 mr-50">~</label>
            <b-form-datepicker
              v-model="query.searchEndDate"
              class="el-def"
              placeholder="게시시작일(To)"
              style="width: 250px"
            />
          </b-col>
          <b-col
            md="4"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="btn-search"
              @click.prevent="fetchData()"
            >
              조회
            </b-button>
            <b-button
              variant="outline-dark"
              class="btn-search ml-1"
              @click.prevent="excelDownload()"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span>다운로드</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card>
      <detail-grid
        :data="drugInfoData"
        :search-type="searchType"
      />
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import { ref, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dayjs from 'dayjs'
import ExcelJS from "exceljs"
import { saveAs } from "file-saver"
import DetailGrid from './grid/DetailGrid.vue'

export default {
  components: {
    DetailGrid,
  },
  props: {
    liveInfoId: {
      type: Number,
      required: true,
    },
    startDate: {
      type: Date,
      required: true,
    },
  },

  setup(props) {
    const query = ref({
      liveInfoId: null,
      searchType: 1,
      clientId: null,
      status: null,
      searchStartDate: dayjs().add(-6, 'day').format('YYYY-MM-DD'),
      searchEndDate: dayjs().format('YYYY-MM-DD'),
    })

    watch(() => props.liveInfoId, newVal => {
      query.value.liveInfoId = newVal
    })
    // watch(() => props.startDate, newVal => {
    //   query.value.searchStartDate = newVal
    // })
    watch(query.value, () => {
      fetchData()
    })

    const toast = useToast()

    const searchType = ref(1)
    const drugInfoData = ref([])

    const fetchData = () => {
      const frDate = dayjs(query.value.searchStartDate)
      const toDate = dayjs(query.value.searchEndDate)

      if (Math.abs(frDate.diff(toDate, 'day')) > 30) {
        toast({
          component: ToastificationContent,
          props: {
            title: '조회 기간은 최대 30일을 넘을 수 없습니다.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
        return
      }

      axios.get('/cl/druginfo/statistics', {
        params: {
          liveInfoId: query.value.liveInfoId,
          searchType: query.value.searchType,
          startDate: query.value.searchStartDate,
          endDate: query.value.searchEndDate,
        },
      })
        .then(rs => {
          searchType.value = query.value.searchType
          drugInfoData.value = rs.data
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '데이터 조회 중 오류가 발생하였습니다.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const grandTotal = ref({
      totalMoaOpenCount: 0,
      totalMoaClickCount: 0,
      totalPrescriptionOpenCount: 0,
      totalPrescriptionClickCount: 0,
      totalScheduleOpenCount: 0,
      totalScheduleClickCount: 0,
      totalDrInfoClickCount: 0,
      totalClickCount: 0,
      totalTabClickCount1: 0,
      totalTabClickCount2: 0,
      totalTabClickCount3: 0,
    })

    const excelDownload = async () => {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet("sheet1")

      worksheet.addRow()

      worksheet.getCell('A1').value = '조회일자'
      worksheet.getCell('B1').value = '진료과'

      worksheet.getCell('C1').value = '모아보기'
      worksheet.mergeCells(`C1:D1`)

      worksheet.getCell('E1').value = '처방팝업'
      worksheet.mergeCells(`E1:F1`)

      worksheet.getCell('G1').value = '스케줄팝업'
      worksheet.mergeCells(`G1:H1`)

      worksheet.getCell('I1').value = '닥터인포'

      worksheet.getCell('J1').value = '총 클릭 수'

      worksheet.getCell('K1').value = '상세보기 클릭 수'
      worksheet.mergeCells(`K1:M1`)

      worksheet.mergeCells(`A1:A2`)
      worksheet.mergeCells(`B1:B2`)
      worksheet.mergeCells(`J1:J2`)

      worksheet.getCell('C2').value = '노출 수'
      worksheet.getCell('D2').value = '클릭 수'

      worksheet.getCell('E2').value = '노출 수'
      worksheet.getCell('F2').value = '클릭 수'

      worksheet.getCell('G2').value = '노출 수'
      worksheet.getCell('H2').value = '클릭 수'

      worksheet.getCell('I2').value = '클릭 수'

      worksheet.getCell('K2').value = '탭1'
      worksheet.getCell('L2').value = '탭2'
      worksheet.getCell('M2').value = '탭3'

      worksheet.columns = [
        { key: "yyyymmdd", width: 15 },
        { key: "medicalDept", width: 20 },
        { key: "totalMoaOpenCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalMoaClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalPrescriptionOpenCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalPrescriptionClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalScheduleOpenCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalScheduleClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalDrInfoClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalTabClickCount1", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalTabClickCount2", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalTabClickCount3", width: 15, style: { numFmt: '#,##0' } },
      ]

      let startRow = 3
      let endRow = startRow

      const stats = drugInfoData.value.statistics

      // eslint-disable-next-line array-callback-return
      stats.map(e => {
        e.detailDepts.map(x => worksheet.addRow({
          yyyymmdd: dayjs(e.yyyymmdd).format('YYYY-MM-DD'),
          medicalDept: x.medicalDept,
          totalMoaOpenCount: x.totalMoaOpenCount,
          totalMoaClickCount: x.totalMoaClickCount,
          totalPrescriptionOpenCount: x.totalPrescriptionOpenCount,
          totalPrescriptionClickCount: x.totalPrescriptionClickCount,
          totalScheduleOpenCount: x.totalScheduleOpenCount,
          totalScheduleClickCount: x.totalScheduleClickCount,
          totalDrInfoClickCount: x.totalDrInfoClickCount,
          totalClickCount: x.totalClickCount,
          totalTabClickCount1: x.totalTabClickCount1,
          totalTabClickCount2: x.totalTabClickCount2,
          totalTabClickCount3: x.totalTabClickCount3,
        }))

        worksheet.addRow({
          yyyymmdd: '소계',
          medicalDept: '',
          totalMoaOpenCount: e.detailDepts.reduce((f, l) => f + l.totalMoaOpenCount, 0),
          totalMoaClickCount: e.detailDepts.reduce((f, l) => f + l.totalMoaClickCount, 0),
          totalPrescriptionOpenCount: e.detailDepts.reduce((f, l) => f + l.totalPrescriptionOpenCount, 0),
          totalPrescriptionClickCount: e.detailDepts.reduce((f, l) => f + l.totalPrescriptionClickCount, 0),
          totalScheduleOpenCount: e.detailDepts.reduce((f, l) => f + l.totalScheduleOpenCount, 0),
          totalScheduleClickCount: e.detailDepts.reduce((f, l) => f + l.totalScheduleClickCount, 0),
          totalDrInfoClickCount: e.detailDepts.reduce((f, l) => f + l.totalDrInfoClickCount, 0),
          totalClickCount: e.detailDepts.reduce((f, l) => f + l.totalClickCount, 0),
          totalTabClickCount1: e.detailDepts.reduce((f, l) => f + l.totalTabClickCount1, 0),
          totalTabClickCount2: e.detailDepts.reduce((f, l) => f + l.totalTabClickCount2, 0),
          totalTabClickCount3: e.detailDepts.reduce((f, l) => f + l.totalTabClickCount3, 0),
        })

        // eslint-disable-next-line no-unused-expressions
        grandTotal.value.totalMoaOpenCount += e.detailDepts.reduce((f, l) => f + l.totalMoaOpenCount, 0)
        grandTotal.value.totalMoaClickCount += e.detailDepts.reduce((f, l) => f + l.totalMoaClickCount, 0)
        grandTotal.value.totalPrescriptionOpenCount += e.detailDepts.reduce((f, l) => f + l.totalPrescriptionOpenCount, 0)
        grandTotal.value.totalPrescriptionClickCount += e.detailDepts.reduce((f, l) => f + l.totalPrescriptionClickCount, 0)
        grandTotal.value.totalScheduleOpenCount += e.detailDepts.reduce((f, l) => f + l.totalScheduleOpenCount, 0)
        grandTotal.value.totalScheduleClickCount += e.detailDepts.reduce((f, l) => f + l.totalScheduleClickCount, 0)
        grandTotal.value.totalDrInfoClickCount += e.detailDepts.reduce((f, l) => f + l.totalDrInfoClickCount, 0)
        grandTotal.value.totalClickCount += e.detailDepts.reduce((f, l) => f + l.totalClickCount, 0)
        grandTotal.value.totalTabClickCount1 += e.detailDepts.reduce((f, l) => f + l.totalTabClickCount1, 0)
        grandTotal.value.totalTabClickCount2 += e.detailDepts.reduce((f, l) => f + l.totalTabClickCount2, 0)
        grandTotal.value.totalTabClickCount3 += e.detailDepts.reduce((f, l) => f + l.totalTabClickCount3, 0)

        endRow += e.detailDepts.length

        worksheet.mergeCells(`A${endRow}:B${endRow}`)
        startRow = endRow + 1
        endRow = startRow
      })

      worksheet.addRow({
        yyyymmdd: '합계',
        medicalDept: '',
        totalMoaOpenCount: grandTotal.value.totalMoaOpenCount,
        totalMoaClickCount: grandTotal.value.totalMoaClickCount,
        totalPrescriptionOpenCount: grandTotal.value.totalPrescriptionOpenCount,
        totalPrescriptionClickCount: grandTotal.value.totalPrescriptionClickCount,
        totalScheduleOpenCount: grandTotal.value.totalScheduleOpenCount,
        totalScheduleClickCount: grandTotal.value.totalScheduleClickCount,
        totalDrInfoClickCount: grandTotal.value.totalDrInfoClickCount,
        totalClickCount: grandTotal.value.totalClickCount,
        totalTabClickCount1: grandTotal.value.totalTabClickCount1,
        totalTabClickCount2: grandTotal.value.totalTabClickCount2,
        totalTabClickCount3: grandTotal.value.totalTabClickCount3,
      })

      worksheet.mergeCells(`A${endRow}:B${endRow}`)

      worksheet.eachRow({ includeEmpty: true }, (row, number) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (number === 1 || number === 2) {
            // eslint-disable-next-line no-param-reassign
            cell.font = {
              bold: true,
            }

            // eslint-disable-next-line no-param-reassign
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D3D3D3' },
            }
          }

          // eslint-disable-next-line no-param-reassign
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }

          if (cell.value === '소계' || cell.value === '합계') {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'center',
            }
          } else {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'left',
            }
          }
        })
      })

      const mimeType = {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }

      const buffer = await workbook.xlsx.writeBuffer()
      const blob = new Blob([buffer], mimeType)
      saveAs(blob, "약품정보 통계(요약).xlsx")
    }

    return {
      query,
      searchType,
      drugInfoData,
      fetchData,
      excelDownload,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.dropdown-menu {
  z-index: 100 !important;
}
</style>
