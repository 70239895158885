<template>
  <div>
    <b-table-simple
      ref="detailGrid"
      bordered
      responsive
      sticky-header
      style="max-height: 65vh; font-size: 12px"
    >
      <colgroup>
        <col style="width: 130px">
        <col style="width: 150px">
        <col style="width: 100px">
        <col style="width: 100px">
        <col style="width: 100px">
        <col style="width: 100px">
        <col style="width: 100px">
        <col style="width: 100px">
        <col style="width: 105px">
        <col style="width: 110px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
      </colgroup>
      <b-thead head-variant="light">
        <b-tr>
          <b-th
            rowspan="2"
            class="sticky-header"
          >
            조회일자
          </b-th>
          <b-th
            rowspan="2"
            class="sticky-header"
          >
            진료과
          </b-th>
          <b-th colspan="2">
            모아보기
          </b-th>
          <b-th colspan="2">
            처방팝업
          </b-th>
          <b-th colspan="2">
            스케줄팝업
          </b-th>
          <b-th>
            닥터인포
          </b-th>
          <b-th rowspan="2">
            총 클릭 수
          </b-th>
          <b-th colspan="3">
            상세보기 클릭 수
          </b-th>
        </b-tr>
        <b-tr>
          <b-th style="top: 38.55px;">
            노출 수
          </b-th>
          <b-th style="top: 38.55px;">
            클릭 수
          </b-th>
          <b-th style="top: 38.55px;">
            노출 수
          </b-th>
          <b-th style="top: 38.55px;">
            클릭 수
          </b-th>
          <b-th style="top: 38.55px;">
            노출 수
          </b-th>
          <b-th style="top: 38.55px;">
            클릭 수
          </b-th>
          <b-th style="top: 38.55px;">
            클릭 수
          </b-th>
          <b-th style="top: 38.55px;">
            탭1
          </b-th>
          <b-th style="top: 38.55px;">
            탭2
          </b-th>
          <b-th style="top: 38.55px;">
            탭3
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody v-if="data">
        <template v-for="detail in data.statistics">
          <template v-for="(member, index) in detail.detailDepts">
            <b-tr
              :key="`member-${detail.yyyymmdd}-${index}`"
            >
              <b-td
                v-if="index === 0"
                :rowspan="(detail.detailDepts.length > 0 ? detail.detailDepts.length : 0)"
                style="left: 0"
              >
                {{ detail.yyyymmdd | $dateFormatter('YYYY-MM-DD') }}
              </b-td>
              <b-td
                style="left: 95px"
              >
                {{ member.medicalDept }}
              </b-td>
              <b-td>{{ member.totalMoaOpenCount | $numberFormatter }}</b-td>
              <b-td>{{ member.totalMoaClickCount | $numberFormatter }}</b-td>
              <b-td>{{ member.totalPrescriptionOpenCount | $numberFormatter }}</b-td>
              <b-td>{{ member.totalPrescriptionClickCount | $numberFormatter }}</b-td>
              <b-td>{{ member.totalScheduleOpenCount | $numberFormatter }}</b-td>
              <b-td>{{ member.totalScheduleClickCount | $numberFormatter }}</b-td>
              <b-td>{{ member.totalDrInfoClickCount | $numberFormatter }}</b-td>
              <b-td>{{ member.totalClickCount | $numberFormatter }}</b-td>
              <b-td>{{ member.totalTabClickCount1 | $numberFormatter }}</b-td>
              <b-td>{{ member.totalTabClickCount2 | $numberFormatter }}</b-td>
              <b-td>{{ member.totalTabClickCount3 | $numberFormatter }}</b-td>
            </b-tr>
          </template>
          <b-tr
            v-if="detail.detailDepts.length > 0"
            :key="`member-total-${detail.yyyymmdd}`"
          >
            <b-td
              colspan="2"
              style="text-align: center; left: 0px;"
            >
              소계
            </b-td>
            <b-td>{{ detail.detailDepts.reduce((f, l) => f + l.totalMoaOpenCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.detailDepts.reduce((f, l) => f + l.totalMoaClickCount, 0) | $numberFormatter }}</b-td>

            <b-td>{{ detail.detailDepts.reduce((f, l) => f + l.totalPrescriptionOpenCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.detailDepts.reduce((f, l) => f + l.totalPrescriptionClickCount, 0) | $numberFormatter }}</b-td>

            <b-td>{{ detail.detailDepts.reduce((f, l) => f + l.totalScheduleOpenCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.detailDepts.reduce((f, l) => f + l.totalScheduleClickCount, 0) | $numberFormatter }}</b-td>

            <b-td>{{ detail.detailDepts.reduce((f, l) => f + l.totalDrInfoClickCount, 0) | $numberFormatter }}</b-td>

            <b-td>{{ detail.detailDepts.reduce((f, l) => f + l.totalClickCount, 0) | $numberFormatter }}</b-td>

            <b-td>{{ detail.detailDepts.reduce((f, l) => f + l.totalTabClickCount1, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.detailDepts.reduce((f, l) => f + l.totalTabClickCount2, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.detailDepts.reduce((f, l) => f + l.totalTabClickCount3, 0) | $numberFormatter }}</b-td>
          </b-tr>
        </template>
        <b-tr>
          <b-td
            colspan="2"
            style="text-align: center; left: 0px;"
          >
            합계
          </b-td>
          <b-td>{{ grandTotal.totalMoaOpenCount | $numberFormatter }}</b-td>
          <b-td>{{ grandTotal.totalMoaClickCount | $numberFormatter }}</b-td>

          <b-td>{{ grandTotal.totalPrescriptionOpenCount | $numberFormatter }}</b-td>
          <b-td>{{ grandTotal.totalPrescriptionClickCount | $numberFormatter }}</b-td>

          <b-td>{{ grandTotal.totalScheduleOpenCount | $numberFormatter }}</b-td>
          <b-td>{{ grandTotal.totalScheduleClickCount | $numberFormatter }}</b-td>

          <b-td>{{ grandTotal.totalDrInfoClickCount | $numberFormatter }}</b-td>

          <b-td>{{ grandTotal.totalClickCount | $numberFormatter }}</b-td>

          <b-td>{{ grandTotal.totalTabClickCount1 | $numberFormatter }}</b-td>
          <b-td>{{ grandTotal.totalTabClickCount2 | $numberFormatter }}</b-td>
          <b-td>{{ grandTotal.totalTabClickCount3 | $numberFormatter }}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import {
  BTableSimple, BThead, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'
import {
  onMounted, ref, watch, getCurrentInstance,
} from '@vue/composition-api'

export default {
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    searchType: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const instance = getCurrentInstance()
    const refs = instance.proxy.$refs

    const grandTotal = ref({
      totalMoaOpenCount: 0,
      totalMoaClickCount: 0,
      totalPrescriptionOpenCount: 0,
      totalPrescriptionClickCount: 0,
      totalScheduleOpenCount: 0,
      totalScheduleClickCount: 0,
      totalDrInfoClickCount: 0,
      totalClickCount: 0,
      totalTabClickCount1: 0,
      totalTabClickCount2: 0,
      totalTabClickCount3: 0,
    })

    const updateGrandTotal = () => {
      // grandTotal 초기화
      grandTotal.value = {
        totalMoaOpenCount: 0,
        totalMoaClickCount: 0,
        totalPrescriptionOpenCount: 0,
        totalPrescriptionClickCount: 0,
        totalScheduleOpenCount: 0,
        totalScheduleClickCount: 0,
        totalDrInfoClickCount: 0,
        totalClickCount: 0,
        totalTabClickCount1: 0,
        totalTabClickCount2: 0,
        totalTabClickCount3: 0,
      }

      // 데이터를 누적하여 grandTotal에 추가
      const stats = props.data.statistics

      stats.forEach(detail => {
        detail.detailDepts.forEach(x => {
          grandTotal.value.totalMoaOpenCount += x.totalMoaOpenCount
          grandTotal.value.totalMoaClickCount += x.totalMoaClickCount
          grandTotal.value.totalPrescriptionOpenCount += x.totalPrescriptionOpenCount
          grandTotal.value.totalPrescriptionClickCount += x.totalPrescriptionClickCount
          grandTotal.value.totalScheduleOpenCount += x.totalScheduleOpenCount
          grandTotal.value.totalScheduleClickCount += x.totalScheduleClickCount
          grandTotal.value.totalDrInfoClickCount += x.totalDrInfoClickCount
          grandTotal.value.totalClickCount += x.totalClickCount
          grandTotal.value.totalTabClickCount1 += x.totalTabClickCount1
          grandTotal.value.totalTabClickCount2 += x.totalTabClickCount2
          grandTotal.value.totalTabClickCount3 += x.totalTabClickCount3
        })
      })
    }

    watch(() => props.data, () => {
      updateGrandTotal()
    })

    onMounted(() => {
      // 딜레이를 줘야 table border가 제대로 적용 됨
      setTimeout(() => {
        refs.detailGrid.$el.classList.add('custom-bordered')
      }, 500)
    })

    return {
      grandTotal,
    }
  },
}
</script>

<style scoped>
.custom-bordered .table th, td {
  border: 1px solid #dee2e6;
}

.table th {
  vertical-align: middle;
}

.sticky-header {
  position: sticky;
  z-index: 99 !important;
}

.dark-layout .sticky-row {
  position: sticky;
  z-index: 3 !important;
  background-color: #283046;
}

.sticky-row {
  position: sticky;
  z-index: 3 !important;
  background-color: #fff;
}
</style>
